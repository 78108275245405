const q1Questions = [
  {
    questionId: '1-1',
    question: 'I will earn $1.00 extra for completing enough orders to meet my assigned target:',
    answerType: 'radio',
    answers: ['Yes', 'No'],
    correctAnswers: ['Yes'],
    incorrectAnswerCount: 0
  },
  {
    questionId: '1-2',
    question: 'For each order I correctly complete that exceeds my assigned target, I will earn an additional $0.05:',
    answerType: 'radio',
    answers: ['Yes', 'No'],
    correctAnswers: ['Yes'],
    incorrectAnswerCount: 0
  },
  {
    questionId: '1-3',
    question: 'Imagine you completed 22 orders and you were assigned to complete 19 orders. Your total bonus for the period would be:',
    answerType: 'text',
    correctAnswers: ['$1.15', '1.15'],
    incorrectAnswerCount: 0
  },
  {
    questionId: '1-4',
    question: 'Imagine you completed 19 orders and you were assigned to complete 20 orders. Your total bonus for the period would be:',
    answerType: 'text',
    correctAnswers: ['$0.00', '0', '$0', '0.00'],
    incorrectAnswerCount: 0
  }
]

const q2Questions = [
  {
    questionId: '2-1',
    question: 'In some periods, unlike my practice periods, an unexpected factor might make it more difficult to meet or exceed my assigned target:',
    answerType: 'radio',
    answers: ['Yes', 'No'],
    correctAnswers: ['Yes'],
    incorrectAnswerCount: 0
  },
  {
    questionId: '2-2',
    question: 'If an adjustment is made to my assigned target, it will be made:',
    answerType: 'radio',
    answers: ['During the 3-minute period', 'At the end of the 3-minute period'],
    correctAnswers: ['At the end of the 3-minute period'],
    incorrectAnswerCount: 0
  }
]


export { q1Questions, q2Questions }