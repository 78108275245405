import Vue from 'vue'
import Vuex from 'vuex'

import { q1Questions, q2Questions } from '@/utils/questions'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    quizzes: {
      q1: {
        questions: q1Questions
      },
      q2: {
        questions: q2Questions
      }
    },
    taskInfo: {
      p1: {},
      p2: {},
      w1: {},
      w2: {},
      w3: {}
    },
    orderTarget: 0
  },
  getters: {
    getTaskInfo(state) {
      return state.taskInfo
    },
    getOrderTarget(state) {
      return state.orderTarget
    },
    getQuizzes(state) {
      return state.quizzes
    }
  },
  mutations: {
    updateTaskInfo(state, task) {
      state.taskInfo[task.id] = { ...task }
    },
    updateOrderTarget(state, newOrderTarget) {
      state.orderTarget = newOrderTarget
    },
    updateQuestionIncorrectAnswer(state, { quizId, questionId }) {
      state.quizzes[quizId].questions.find(question => question.questionId === questionId).incorrectAnswerCount += 1
    }
  },
  actions: {
    fetchTaskInfoById({ getters, commit }, id) {
      return new Promise((resolve, reject) => {
        const taskInfo = getters.getTaskInfo
        if (id === 'p2') {
          commit('updateOrderTarget', Math.max(taskInfo['p1'].correctOrderCount, taskInfo['p2'].correctOrderCount))
        }
        resolve(taskInfo[id])
      })
    },
    storeTaskInfo({ commit }, taskPayload) {
      commit('updateTaskInfo', taskPayload)
    }
  }
})
