import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/home')
  },
  {
    path: '/rules',
    name: 'Rules',
    component: () => import(/* webpackChunkName: "Rules" */ '../views/rules')
  },
  {
    path: '/task-instructions',
    name: 'Task Instructions',
    component: () => import(/* webpackChunkName: "Task Instructions" */ '../views/task-instructions')
  },
  {
    path: '/practice-instructions',
    name: 'Practice Instructions',
    component: () => import(/* webpackChunkName: "Practice Instructions" */ '../views/practice-instructions')
  },
  {
    path: '/task',
    name: 'Task',
    component: () => import(/* webpackChunkName: "Task" */ '../views/task')
  },
  {
    path: '/task-results',
    name: 'Task Results',
    component: () => import(/* webpackChunkName: "Task Results" */ '../views/task-results')
  },
  {
    path: '/pay-details',
    name: 'Pay Details',
    component: () => import(/* webpackChunkName: "Pay Details" */ '../views/pay-details')
  },
  {
    path: '/quiz',
    name: 'Quiz',
    component: () => import(/* webpackChunkName: "Quiz" */ '../views/quiz')
  },
  {
    path: '/adjustment-details',
    name: 'Adjustment Details',
    component: () => import(/* webpackChunkName: "Adjustment Details" */ '../views/adjustment-details')
  },
  {
    path: '/unfinished',
    name: 'Unfinished',
    component: () => import(/* webpackChunkName: "Unfinished" */ '../views/unfinished')
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
